/* eslint-disable max-len */
const changePasswordSvgIcon = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <g clipPath="url(#clip0_731_4420)">
    <path d="M25.1982 11.2853H23.744C23.2887 5.21913 18.2917 0.423584 12.2053 0.423584C5.81962 0.423584 0.625 5.70116 0.625 12.1878C0.625 15.6072 2.07098 18.6915 4.37367 20.8466C4.36112 20.6769 4.34859 20.5157 4.34023 20.3715C4.3026 19.6884 4.31515 18.9587 4.37783 18.229C3.11575 16.549 2.36768 14.4575 2.36768 12.1878C2.36768 6.67691 6.78081 2.19269 12.2053 2.19269C17.3301 2.19269 21.5501 6.19588 22.0009 11.2853H20.6267C20.2915 11.2853 20.082 11.6537 20.2496 11.9484L22.5354 15.9668C22.703 16.2615 23.122 16.2615 23.2896 15.9668L25.5754 11.9484C25.743 11.6537 25.5335 11.2853 25.1982 11.2853Z" fill="white"/>
    <path d="M14.4717 12.9981C12.8001 12.6332 10.7022 12.6332 9.03054 12.9938V10.4781C9.03054 8.95503 10.2509 7.71625 11.7511 7.71625C13.2515 7.71625 14.4717 8.95503 14.4717 10.4781V12.9981ZM16.9082 14.1096C16.6992 13.8975 16.4276 13.715 16.1058 13.5496V10.4781C16.1058 8.03867 14.1541 6.05322 11.7511 6.05322C9.34815 6.05322 7.39652 8.03868 7.39652 10.4781V13.5496C7.07472 13.715 6.79891 13.8975 6.59414 14.1096C4.76787 15.9593 4.58815 22.9338 6.59414 24.5799C7.92309 25.6702 11.141 26.0393 13.7446 25.6829V21.7459C13.7446 20.7702 14.4467 19.9599 15.3661 19.8114V18.0805C15.3661 17.4229 15.8926 16.8926 16.5362 16.8926C16.8497 16.8926 17.1422 17.0156 17.3595 17.2405L18.2789 18.1738C18.1117 16.4429 17.6437 14.8563 16.9082 14.1096ZM12.8335 21.2199H10.6688L11.0616 19.1623C10.6019 18.912 10.2843 18.4199 10.2843 17.8472C10.2843 17.0284 10.9404 16.3623 11.7511 16.3623C12.5619 16.3623 13.218 17.0284 13.218 17.8472C13.218 18.4199 12.9046 18.912 12.4407 19.1623L12.8335 21.2199Z" fill="white"/>
    <path d="M26.6964 20.6373H19.5236L16.7687 17.8407C16.5593 17.6282 16.2014 17.7787 16.2014 18.0793V20.6373H15.6695C15.0668 20.6373 14.5781 21.1334 14.5781 21.7453V26.4685C14.5781 27.0804 15.0668 27.5765 15.6695 27.5765H26.6964C27.2992 27.5765 27.7878 27.0804 27.7878 26.4685V21.7453C27.7878 21.1334 27.2992 20.6373 26.6964 20.6373ZM17.9197 24.9969L17.465 25.319L16.9745 24.6526L16.4764 25.319L16.0223 24.9969L16.5128 24.2864L15.7626 24.052L15.9283 23.5174L16.6644 23.7664V22.8948H17.2777V23.7664L18.0061 23.5174L18.1794 24.052L17.4216 24.2864L17.9197 24.9969ZM20.7277 24.9969L20.273 25.319L19.7825 24.6526L19.2844 25.319L18.8303 24.9969L19.3208 24.2864L18.5706 24.052L18.7363 23.5174L19.4723 23.7664V22.8948H20.0856V23.7664L20.8141 23.5174L20.9873 24.052L20.2296 24.2864L20.7277 24.9969ZM23.5357 24.9969L23.081 25.319L22.5905 24.6526L22.0923 25.319L21.6382 24.9969L22.1287 24.2864L21.3786 24.052L21.5442 23.5174L22.2803 23.7664V22.8948H22.8936V23.7664L23.622 23.5174L23.7953 24.052L23.0375 24.2864L23.5357 24.9969ZM26.3437 24.9969L25.889 25.319L25.3985 24.6526L24.9003 25.319L24.4462 24.9969L24.9367 24.2864L24.1866 24.052L24.3522 23.5174L25.0883 23.7664V22.8948H25.7016V23.7664L26.43 23.5174L26.6033 24.052L25.8455 24.2864L26.3437 24.9969Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_731_4420">
      <rect width="28" height="28" fill="white"/>
    </clipPath>
  </defs>
</svg>;
export {changePasswordSvgIcon};

/* eslint-enable max-len */