/* eslint-disable max-len */
const partnersSvgIcon = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 27 20" fill="none">
  <g clipPath="url(#clip0_890_4503)">
    <path d="M9.50355 15.1907C8.3212 14.6842 7.88292 14.2076 7.88292 12.8362C7.88292 12.8362 10.7101 11.4996 10.7101 7.56209C10.7101 4.87532 8.85994 3.3584 6.5775 3.3584C4.29506 3.3584 2.44524 4.87532 2.44524 7.56209C2.44524 7.90086 2.46628 8.22117 2.50427 8.5224C2.52719 8.69901 2.87536 8.56732 2.90895 8.73101C3.48474 11.5384 5.27271 12.8369 5.27271 12.8362C5.27271 16.6246 -0.00390625 15.2606 -0.00390625 18.9753C-0.00390625 18.9753 1.65471 18.9753 6.02023 18.9753C6.01741 17.0513 7.47415 15.879 9.50355 15.1907Z" fill="white"/>
    <path d="M18.4882 11.5845C18.4882 11.5845 20.517 10.6261 21.4419 8.00761C21.5495 7.70269 22.023 7.855 22.0958 7.50423C22.2079 6.96484 22.3665 5.871 22.3665 5.21561C22.3665 1.98084 19.6646 0.174072 16.9172 0.174072C14.1698 0.174072 11.9423 2.00023 11.9423 5.235C11.9423 5.6433 11.9677 6.02853 12.0138 6.391C12.0411 6.6033 12.4603 6.44484 12.5011 6.64207C13.1937 10.0212 15.3461 11.5851 15.3461 11.5845C15.3461 13.9661 13.0241 14.6562 10.8054 15.4088C8.7754 16.0971 6.83203 16.8387 6.83203 18.9753C7.01695 18.9753 7.20124 18.9753 7.3893 18.9753C12.016 18.9753 15.7671 18.9753 15.7671 18.9753C16.1448 18.9753 16.5282 18.9753 16.9172 18.9753C22.4873 18.9753 27.0029 18.9753 27.0029 18.9753C27.0029 14.503 18.4882 16.1448 18.4882 11.5845Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_890_4503">
      <rect width="27" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export {partnersSvgIcon};

/* eslint-enable max-len */