export enum ROUTES_ENUM {
  HOME = "/",
  ACTIVITY = "/activity",
  CONTACTS = "/contacts",
  DEMINING = "/demining",
  ADMIN_PARTNERS = "partners",
  ADMIN_OUR_CONTACTS = "contacts",
  ADMIN_FEEDBACK = "feedback",
  FEEDBACK_DETAILS = ":id",
  ADMIN_REPORTS = "reports",
  ADMIN = "admin",
  AUTH = "auth",
  LOGIN = "login",
  FORGOT_PASSWORD = "forgot-password",
  UPDATE_PASSWORD = "update-password",
  CHANGE_PASSWORD = "change-password",
  NOT_FOUND = "/*",
}
